@font-face {
  font-family: "GTWalsheimPro";
  src: url("../font/GT-Walsheim-Regular.woff2") format("woff2"), url("../font/GT-Walsheim-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muller";
  src: url("../font/MullerRegular.woff2") format("woff2"), url("../font/MullerRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muller";
  src: url("../font/MullerMedium.woff2") format("woff2"), url("../font/MullerRegular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Muller";
  src: url("../font/MullerBold.woff2") format("woff2"), url("../font/MullerBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --color-white: #FDFDFD;
  --color-black: #060606;
  --color-header:#5E4F9C;
  --color-main-gradient:linear-gradient(81.55deg, #5E4F9C 3.19%, #473281 96.81%);
  --color-orange:#F18606;
  --color-violet:#DDD9F8;
  --color-violet-light:#F2F0FF;
  --ML:'Muller', sans-serif;
}

*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

mark {
  -webkit-text-fill-color: initial;
  text-fill-color: transparent;
  background: none;
}

.img {
  display: block;
}

.img.contain img {
  -o-object-fit: contain;
  object-fit: contain;
}

.img > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

button {
  background: transparent;
}

h1 {
  margin: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1520px) {
  html {
    font-size: 9.5px;
  }
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 6.4px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 4.8px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
